import createDefaultVideoInputValue from "../../utils/createDefaultVideoInputValue";
import { DefaultValues, useFieldArray, useFormContext } from "react-hook-form";
import { IAnswer, IQuestion } from "../../domain/e-learning/e-learning.types";
import { ExerciseFormValues } from "../ExerciseForm/ExerciseForm";
import { IoAddCircle, IoTrashSharp } from "react-icons/io5";
import { useFileInput } from "../../utils/useFileInput";
import AnswerForm from "../AnswerForm/AnswerForm";
import IconButton from "../IconButton/IconButton";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import "./QuestionForm.scss";
import { FC } from "react";

export type QuestionFormValues = IQuestion & {
  correctionVideo: FileList;
  video: FileList;
};

interface QuestionFormProps {
  questionId: string;
  removeQuestion: (index: number) => void;
  index?: number;
  defaultValues?: DefaultValues<QuestionFormValues>;
}

const QuestionForm: FC<QuestionFormProps> = ({
  questionId,
  index = 0,
  removeQuestion,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const { register, errors, control } = useFormContext<ExerciseFormValues>();
  const { fields, append, remove } = useFieldArray<IAnswer>({
    name: `questions[${index}].answers`,
    control,
  });

  const requiredErrorMessage = t("field_required");

  const onAddAnswerClick = () => {
    append({ questionId });
  };

  const videoInputRef = useFileInput(
    (defaultValues?.video as FileList) || null,
  );
  const correctionVideoInputRef = useFileInput(
    (defaultValues?.correctionVideo as FileList) || null,
  );

  return (
    <>
      <input
        name={`questions[${index}].id`}
        defaultValue={questionId}
        type="hidden"
        ref={register()}
      />
      <div className="question-header">
        <span className="m-typography-body1">{`${t("question")} n°${index + 1}`}</span>

        <IconButton
          className="delete-icon"
          color="dangerous"
          onClick={() => removeQuestion(index)}
        >
          <IoTrashSharp />
        </IconButton>
      </div>
      <div className="m-form-fields-content">
        <div className="m-form-field m-form-field-full-width">
          <label htmlFor={`questions[${index}].text`} className="m-form-label">
            {`${t("e_learning_question")} n°${index + 1}`}
          </label>
          <input
            id={`questions[${index}].text`}
            name={`questions[${index}].text`}
            className={classNames("m-form-input", {
              "m-form-input-error": Boolean(errors.questions?.[index]?.text),
            })}
            ref={register({ required: requiredErrorMessage })}
            aria-invalid={Boolean(errors.questions?.[index]?.text)}
            defaultValue={defaultValues?.text ? defaultValues.text : ""}
          />
        </div>
        <div className="m-form-field m-form-field-full-width">
          <label htmlFor={`questions[${index}].video`} className="m-form-label">
            {t("add_video")}
          </label>
          <input
            name={`questions[${index}].video`}
            id={`questions[${index}].video`}
            type="file"
            accept="video/*"
            className={classNames("m-form-input", {
              "m-form-input-error": Boolean(errors.questions?.[index]?.video),
            })}
            ref={(event) => {
              register(event, { required: requiredErrorMessage });
              videoInputRef.current = event;
            }}
            aria-invalid={Boolean(errors.questions?.[index]?.video)}
          />
        </div>
        <div className="m-form-field m-form-field-full-width">
          <label
            htmlFor={`questions[${index}].correctionVideo`}
            className="m-form-label"
          >
            {t("add_correction_video")}
          </label>
          <input
            name={`questions[${index}].correctionVideo`}
            id={`questions[${index}].correctionVideo`}
            type="file"
            accept="video/*"
            className={classNames("m-form-input", {
              "m-form-input-error": Boolean(
                errors.questions?.[index]?.correctionVideo,
              ),
            })}
            ref={(event) => {
              register(event, { required: requiredErrorMessage });
              correctionVideoInputRef.current = event;
            }}
            aria-invalid={Boolean(errors.questions?.[index]?.correctionVideo)}
          />
        </div>
      </div>
      <span className="m-typography-body1">{t("e_learning_answers")}</span>

      {fields.map((answer, i) => (
        <AnswerForm
          key={answer.id}
          answerId={answer.id ? answer.id : uuid()}
          index={i}
          questionIndex={index}
          removeAnswer={remove}
          defaultValues={{
            ...answer,
            video: createDefaultVideoInputValue(answer.videoId),
          }}
        />
      ))}

      <Button
        className="m-form-fields-content m-form-fields-content-full-width"
        size="large"
        variant="transparent"
        startIcon={<IoAddCircle />}
        onClick={onAddAnswerClick}
      >
        <p className="m-typography-body1">{t("e_learning_add_answer")}</p>
      </Button>
    </>
  );
};

export default QuestionForm;
