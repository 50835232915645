import { UpdateThemeActionPayload } from "../../domain/e-learning/e-learning.types";
import createDefaultVideoInputValue from "../../utils/createDefaultVideoInputValue";
import { selectThemeById } from "../../domain/e-learning/e-learning.selectors";
import { updateTheme } from "../../domain/e-learning/e-learning.actions";
import ThemeForm from "../../components/ThemeForm/ThemeForm";
import createFileList from "../../utils/createFileList";
import { useDispatch, useSelector } from "react-redux";
import useNavigate from "../../utils/useNavigate";
import { useParams } from "react-router-dom";
import { FC } from "react";

const EditTheme: FC = () => {
  const params = useParams<{ id: string }>();
  const defaultValues = useSelector(selectThemeById(params.id));
  const dispatch = useDispatch();
  const navigate = useNavigate("/e-learning");
  const onSubmit = (data: UpdateThemeActionPayload) => {
    dispatch(updateTheme(params.id, data));
    navigate();
  };

  return (
    <ThemeForm
      title="e_learning_edit"
      onSubmit={onSubmit}
      defaultValues={{
        ...defaultValues,
        picto: defaultValues?.pictoName
          ? createFileList(defaultValues.pictoName)
          : "",
        video: createDefaultVideoInputValue(defaultValues?.videoId),
      }}
    />
  );
};

export default EditTheme;
