import {
  ICourse,
  UpdateCourseActionPayload,
} from "../../../domain/e-learning/e-learning.types";
import createDefaultVideoInputValue from "../../../utils/createDefaultVideoInputValue";
import { selectCourseById } from "../../../domain/e-learning/e-learning.selectors";
import { updateCourse } from "../../../domain/e-learning/e-learning.actions";
import StepNav, { Step } from "../../../components/StepBar/StepNav";
import CourseForm from "../../../components/CourseForm/CourseForm";
import LeftSidebar from "../../../components/Page/LeftSidebar";
import createFileList from "../../../utils/createFileList";
import { generatePath, useParams } from "react-router-dom";
import Content from "../../../components/Page/Content";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "../../../components/Page/Topbar";
import useNavigate from "../../../utils/useNavigate";
import Page from "../../../components/Page/Page";
import mapWithId from "../../../utils/mapWithId";
import { AppState } from "../../../redux/types";
import { FC, useMemo } from "react";

const EditCourse: FC = () => {
  const params = useParams<{ themeId: string; courseId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate(`/e-learning?theme="${params.themeId}"`);
  const onSubmit = (data: UpdateCourseActionPayload) => {
    dispatch(updateCourse(params.courseId, data));
    navigate();
  };

  const defaultValues = useSelector<AppState, ICourse | undefined>(
    selectCourseById(params.themeId, params.courseId),
  );

  const steps = useMemo<Step[]>(
    () =>
      mapWithId([
        {
          label: "e_learning_edit",
          to: generatePath("/e-learning/:id/edit", { id: params.themeId }),
        },
        {
          label: "e_learning_edit_course",
          to: generatePath("/e-learning/:id/courses/:courseId", {
            id: params.themeId,
            courseId: params.courseId,
          }),
        },
        {
          label: "e_learning_add_exercises",
          to: generatePath("/e-learning/:id/exercises/add", {
            id: params.themeId,
          }),
        },
      ]),
    [params],
  );

  return (
    <Page disabledGutters>
      <Topbar className="add-theme-topbar">
        <StepNav steps={steps} />
      </Topbar>
      <LeftSidebar />
      <Content>
        <CourseForm
          title="e_learning_edit_course"
          onSubmit={onSubmit}
          defaultValues={{
            ...defaultValues,
            picto: defaultValues?.pictoName
              ? createFileList(defaultValues.pictoName)
              : "",
            video: createDefaultVideoInputValue(defaultValues?.videoId),
          }}
        />
      </Content>
    </Page>
  );
};

export default EditCourse;
