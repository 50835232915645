import createDefaultVideoInputValue from "../../utils/createDefaultVideoInputValue";
import { DefaultValues, useForm } from "react-hook-form";
import { DictionnaryEntry } from "./dictionnaryEntry";
import Button from "../../components/Button/Button";
import Paper from "../../components/Paper/Paper";
import { useTranslation } from "react-i18next";
import "./addDictionnaryEntry.scss";
import classNames from "classnames";
import { FC } from "react";

export type DictionnaryFormProps = {
  title: string;
  onSubmit: (dictionnaryEntry: DictionnaryEntry) => void;
  defaultValues?: DefaultValues<DictionnaryEntry>;
};

const DictionnaryEntryForm: FC<DictionnaryFormProps> = ({
  title,
  defaultValues = {},
  onSubmit,
}: DictionnaryFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, register, errors } = useForm<DictionnaryEntry>({
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues: {
      ...defaultValues,
      video: createDefaultVideoInputValue(defaultValues.videoId),
    },
  });

  const requiredErrorMessage = t("field_required");

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="m-form add-dictionnary-entry"
      encType="multipart/form-data"
    >
      <Paper className="m-form-fields" fullWidth>
        <h2 className="m-form-fields-title fg-color-primary">{t(title)}</h2>
        <div className="m-form-fields-content m-form-fields-content-col-2">
          <div className="m-form-field m-form-field-full-width">
            <label htmlFor="word" className="m-form-label">
              {t("word-name")}
            </label>
            <input
              name="word"
              id="word"
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.word),
              })}
              ref={register({ required: requiredErrorMessage })}
              aria-invalid={Boolean(errors.word)}
            />
          </div>
          <div className="m-form-field m-form-field-full-width">
            <label htmlFor="definition" className="m-form-label">
              {t("word-definition")}
            </label>
            <input
              name="definition"
              id="definition"
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.definition),
              })}
              ref={register({ required: requiredErrorMessage })}
              aria-invalid={Boolean(errors.definition)}
            />
          </div>
          <div className="m-form-field m-form-field-full-width">
            <label htmlFor="video" className="m-form-label">
              {t("add_video")}
            </label>
            <input
              name="video"
              id="video"
              type="file"
              accept="video/*"
              className={classNames("m-form-input", {
                "m-form-input-error": Boolean(errors.video),
              })}
              ref={register({ required: requiredErrorMessage })}
              aria-invalid={Boolean(errors.video)}
            />
          </div>
        </div>
      </Paper>
      <Paper className="m-form-actions" fullWidth>
        <Button type="submit" className="m-form-action-main">
          {t("save")}
        </Button>
        <Button color="dangerous" variant="outlined" to="/dictionnary">
          {t("cancel")}
        </Button>
      </Paper>
    </form>
  );
};

export default DictionnaryEntryForm;
