import { useEffect, useRef } from "react";

export const useFileInput = (fileList: FileList | null) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (fileInputRef.current && fileList instanceof FileList) {
      if (fileList.length > 0) {
        fileInputRef.current.files = fileList;
      }
    }
  }, [fileList]);

  return fileInputRef;
};
