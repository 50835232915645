import { IAnswer } from "../../domain/e-learning/e-learning.types";
import { ExerciseFormValues } from "../ExerciseForm/ExerciseForm";
import { DefaultValues, useFormContext } from "react-hook-form";
import { useFileInput } from "../../utils/useFileInput";
import IconButton from "../IconButton/IconButton";
import { IoTrashSharp } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Paper from "../Paper/Paper";
import "./AnswerForm.scss";
import { FC } from "react";

export type AnswerFormValues = IAnswer & {
  video: FileList;
};

interface AnswerFormProps {
  questionIndex: number;
  answerId: string;
  index?: number;
  removeAnswer: (index: number) => void;
  defaultValues: DefaultValues<AnswerFormValues>;
}

const AnswerForm: FC<AnswerFormProps> = ({
  questionIndex,
  index = 0,
  removeAnswer,
  answerId,
  defaultValues,
}) => {
  const { t } = useTranslation();
  const requiredErrorMessage = t("field_required");
  const { register, errors } = useFormContext<ExerciseFormValues>();

  const videoInputRef = useFileInput((defaultValues.video as FileList) || null);

  return (
    <>
      <Paper fullWidth secondary className="m-form-fields">
        <div className="m-form-field m-form-field-full-width">
          <div className="answer-checkbox-input-wrapper m-typography-h3">
            <div className="answer-header">
              <input
                name={`questions[${questionIndex}].answers[${index}].id`}
                defaultValue={answerId}
                type="hidden"
                ref={register()}
              />
              <input
                type="checkbox"
                className="answer-checkbox-input"
                key={`${String.fromCharCode(index + "A".charCodeAt(0))}`}
                id={`questions[${questionIndex}].answers[${index}].isCorrectAnswer`}
                name={`questions[${questionIndex}].answers[${index}].isCorrectAnswer`}
                disabled={false}
                ref={register}
                defaultChecked={defaultValues.isCorrectAnswer}
              />
              {`${t("e_learning_answer")} ${String.fromCharCode(index + "A".charCodeAt(0))}`}

              <IconButton
                className="delete-icon"
                color="dangerous"
                onClick={() => removeAnswer(index)}
              >
                <IoTrashSharp />
              </IconButton>
            </div>
          </div>
          <label
            htmlFor={`questions[${questionIndex}].answers[${index}].text`}
            className="m-form-label"
          >
            {`${t("e_learning_add_the_answer")} ${String.fromCharCode(index + "A".charCodeAt(0))}`}
          </label>
          <input
            id={`questions[${questionIndex}].answers[${index}].text`}
            name={`questions[${questionIndex}].answers[${index}].text`}
            className={classNames("outlined", {
              "m-form-input-error": Boolean(
                errors.questions?.[questionIndex]?.answers?.[index]?.text,
              ),
            })}
            ref={register({
              required: requiredErrorMessage,
            })}
            aria-invalid={Boolean(
              errors.questions?.[questionIndex]?.answers?.[index]?.text,
            )}
            defaultValue={defaultValues.text}
          />
        </div>
        <div className="m-form-field m-form-field-full-width">
          <label
            htmlFor={`questions[${questionIndex}].answers[${index}].video`}
            className="m-form-label"
          >
            {t("add_video")}
          </label>
          <input
            name={`questions[${questionIndex}].answers[${index}].video`}
            id={`questions[${questionIndex}].answers[${index}].video`}
            type="file"
            accept="video/*"
            className={classNames("m-form-input", {
              "m-form-input-error": Boolean(
                errors.questions?.[questionIndex]?.answers?.[index]?.video,
              ),
            })}
            ref={(event) => {
              register(event, { required: requiredErrorMessage });
              videoInputRef.current = event;
            }}
            aria-invalid={Boolean(
              errors.questions?.[questionIndex]?.answers?.[index]?.video,
            )}
            defaultValue={defaultValues.video?.vttName}
          />
        </div>
      </Paper>
    </>
  );
};

export default AnswerForm;
