import {
  CreateExerciseActionPayload,
  IQuestion,
  UpdateExerciseActionPayload,
} from "../../domain/e-learning/e-learning.types";
import {
  DefaultValues,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import createDefaultVideoInputValue from "../../utils/createDefaultVideoInputValue";
import QuestionForm from "../QuestionForm/QuestionForm";
import StepNav, { Step } from "../StepBar/StepNav";
import { useTranslation } from "react-i18next";
import LeftSidebar from "../Page/LeftSidebar";
import { IoAddCircle } from "react-icons/io5";
import { useParams } from "react-router-dom";
import Button from "../Button/Button";
import Content from "../Page/Content";
import Topbar from "../Page/Topbar";
import classNames from "classnames";
import Paper from "../Paper/Paper";
import Title from "../Page/Title";
import { v4 as uuid } from "uuid";
import "./ExerciseForm.scss";
import { FC } from "react";

export type ExerciseFormValues =
  | CreateExerciseActionPayload
  | UpdateExerciseActionPayload;

type Mode = "add" | "edit";

interface ExerciseFormProps {
  themeId?: string;
  mode: Mode;
  steps: Step[];
  defaultValues?: DefaultValues<ExerciseFormValues>;
  onSubmit: (data: ExerciseFormValues) => void;
}

const ExerciseForm: FC<ExerciseFormProps> = ({
  mode,
  defaultValues = {},
  onSubmit,
  steps,
}) => {
  const params = useParams<{ themeId: string; exerciseId: string }>();
  const { t } = useTranslation();

  const methods = useForm<ExerciseFormValues>({
    criteriaMode: "all",
    shouldFocusError: false,
    defaultValues,
    mode: "onBlur",
  });

  const { fields, append, remove } = useFieldArray<IQuestion>({
    name: "questions",
    control: methods.control,
  });

  const requiredErrorMessage = t("field_required");
  const onAddQuestionBtnClick = () => {
    append({
      id: uuid(),
      exerciseId: params.exerciseId,
    });
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          className="page page-disabled-gutters"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Topbar className="add-theme-topbar">
            <StepNav steps={steps} />
          </Topbar>
          <LeftSidebar className="add-exercise-left-sidebar" />
          <Content>
            <Title>
              {t(
                mode === "add"
                  ? "e_learning_add_exercises"
                  : "e_learning_edit_exercise",
              )}
            </Title>
            <Paper fullWidth className="m-form-fields">
              <h2 className="m-typography-h2">
                {t(
                  mode === "add"
                    ? "e_learning_create_quizz"
                    : "e_learning_modify_quizz",
                )}
              </h2>
              <div className="m-form-field m-form-field-full-width">
                <label htmlFor="title" className="m-form-label">
                  {t("title")}
                </label>
                <input
                  id="title"
                  name="title"
                  className={classNames("m-form-input", {
                    "m-form-input-error": Boolean(methods.errors.title),
                  })}
                  ref={methods.register({ required: requiredErrorMessage })}
                  aria-invalid={Boolean(methods.errors.title)}
                />
              </div>
              <span className="m-typography-body1">
                {t("e_learning_complementary_support")}
              </span>
              <div className="m-form-fields-content m-form-fields-content-col-2">
                <div className="m-form-field">
                  <label htmlFor="video" className="m-form-label">
                    {t("add_video")}
                  </label>
                  <input
                    name="video"
                    id="video"
                    type="file"
                    accept="video/*"
                    className={classNames("m-form-input", {
                      "m-form-input-error": Boolean(methods.errors.video),
                    })}
                    ref={methods.register({ required: requiredErrorMessage })}
                    aria-invalid={Boolean(methods.errors.video)}
                  />
                </div>
                <div className="m-form-field">
                  <label htmlFor="picto" className="m-form-label">
                    {t("e_learning_picto_field")}
                  </label>
                  <input
                    name="picto"
                    id="picto"
                    type="file"
                    accept="image/png, image/jpeg"
                    className={classNames("m-form-input", {
                      "m-form-input-error": Boolean(methods.errors.picto),
                    })}
                    ref={methods.register({ required: requiredErrorMessage })}
                    aria-invalid={Boolean(methods.errors.picto)}
                  />
                </div>
              </div>
              {fields?.map((question, index) => (
                <QuestionForm
                  key={question.id}
                  questionId={question.id ? question.id : uuid()}
                  index={index}
                  removeQuestion={remove}
                  defaultValues={{
                    ...question,
                    video: createDefaultVideoInputValue(question.videoId),
                    correctionVideo: createDefaultVideoInputValue(
                      question.correctionVideoId,
                    ),
                  }}
                />
              ))}
            </Paper>
            <Button
              size="large"
              variant="transparent"
              startIcon={<IoAddCircle />}
              onClick={onAddQuestionBtnClick}
            >
              <p className="m-typography-body1">
                {t("e_learning_add_question")}
              </p>
            </Button>
            <Paper fullWidth className="m-form-actions">
              <Button type="submit">{t("save")}</Button>
              <Button
                to={`/e-learning/${params.themeId}/exercises/add}`}
                className="m-form-action-main"
                disabled
              >
                {t("e_learning_add_exercises")}
              </Button>
              <Button
                to={`/e-learning?theme="${params.themeId}"`}
                color="dangerous"
                variant="outlined"
              >
                {t("cancel")}
              </Button>
            </Paper>
          </Content>
        </form>
      </FormProvider>
    </>
  );
};

export default ExerciseForm;
